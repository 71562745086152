import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Content from '../components/content'

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`

const ContentPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data

  return (
    <Layout>
      <SEO title={markdownRemark && markdownRemark.frontmatter.title} />
      <Content content={markdownRemark} />
    </Layout>
  )
}

export default ContentPage