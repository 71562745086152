import React from 'react'

import styles from './content.module.css'

type props = {
  content: {[key: string]: string}
}

const Content = ({ content }: props) => { 
  console.log(content)
  return <div dangerouslySetInnerHTML={{ __html: content.html }} className={styles.content}/>
}

export default Content
